import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Footer = props => (
  <footer className="footer">
    <Container className="pt-5">
      <Row>
        <Col>
          <h6>Länkar</h6>
          <ul className="footer_links">
            <li>
              <Link to="/">Hem</Link>
            </li>
            <li>
              <Link to="/vanliga-fragor">Vanliga frågor</Link>
            </li>
            <li>
              <Link to="/kopvillkor">Köpvillkor</Link>
            </li>
          </ul>
          <hr className="d-block d-sm-none pb-3" />
        </Col>
        <Col xs={12} sm={true}>
          <h6>{props.siteTitle}</h6>
          <p>
            Vi älskar kaffe och att prova nya smaker. Därför erbjuder vi också
            dig, eller en vän, en möjlighet att under en tid prova på de finaste
            kaffesorter som finns. Vilken blir din nästa favorit?
          </p>
        </Col>
        <Col xs={12} sm={true}>
          <hr className="d-block d-sm-none pb-3" />
          <h6>Företagare</h6>
          <p>
            Vill du som företagare veta mer om hur du kan belöna kontoret och
            dina medarbetare med lite nya spännade smaker med jämna mellanrum?{" "}
            <Link to="/b2b">Läs mer och hör av dig till oss &raquo;</Link>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="small">
            © {new Date().getFullYear()} {props.siteTitle}, Built by {` `}
            <a href="https://jmgraphic.se">Jmgraphic.se</a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
