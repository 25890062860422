import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"

const Hero = props => {
  const { site, beansLg, beansSm } = useStaticQuery(graphql`
    query SiteHeroQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      beansLg: file(relativePath: { eq: "beans.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      beansSm: file(relativePath: { eq: "beans.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 490) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  let title = props.title || site.siteMetadata.title
  let subTitle = props.subTitle || site.siteMetadata.description

  const sources = [
    beansSm.childImageSharp.fluid,
    {
      ...beansLg.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
  ]

  return (
    <section className="header-hero">
      <div className="hero-wrapper">
        <BackgroundImage
          Tag="section"
          className="hero-bg"
          fluid={sources}
          backgroundColor={`#583e18`}
          style={{position: "absolute"}}
        ></BackgroundImage>
        <div className="overlay"></div>
        <Container>
          <Row>
            <div className="col-md-7">
              <header className="entry-header">
                <p className="title">{title}</p>
                <p className="subTitle">{subTitle}</p>
              </header>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Hero
