/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "./hero"
import Header from "./header"
import Footer from "./footer"
import Container from "react-bootstrap/Container"
import "../styles/coffee.scss"

const Layout = ({ children, hero }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let displayHero
  let mainMargin = "margin"

  if (hero) {
    displayHero = <Hero />
    mainMargin = ""
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {displayHero}

      <Container>
        <main className={mainMargin}>{children}</main>
      </Container>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.bool,
}

export default Layout
