import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

const Header = ({ siteTitle }) => (
  <header>
    <Navbar collapseOnSelect expand="md" fixed="top" bg="dark" variant="dark">
      <Navbar.Toggle aria-controls="resp-navbar" />
      <Link className="navbar-brand mx-auto mr-sm-5" to="/">
        {siteTitle}
      </Link>

      <button className="order-sm-2 btn btn-outline-light my-2 my-sm-0 snipcart-checkout">
        Betala <span className="snipcart-total-price"></span>
      </button>

      <Navbar.Collapse id="resp-navbar" className="order-sm-1">
        <Nav className="mr-auto">
          <Link className="nav-link" to="/smakpaket">
            Våra smakpaket
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
